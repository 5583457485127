<template>
  <div id="MenuRelatorios" style="min-width: 300px!important;">
    <v-treeview
      v-if="menu_config.length > 0"
      ref="tree"
      v-model="tree_opcoes"
      :items="menu_config"
      :open-all="false"
      item-key="id"
      open-on-click
      return-object
      activatable
      dense
      expand-icon="mdi-chevron-down"
      on-icon="mdi-chevron"
      off-icon="mdi-chevron"
      indeterminate-icon="mdi-chevron"
      @update:active="abreRelatorioExterno"
      class="ml-3 mr-3"
    >
      <template v-slot:prepend="{ item }">
        <span>
          <v-icon
            clsss="pa-0"
            v-if="!item.children">
            {{ item.icon }}
            </v-icon>
          </span>
      </template>
    </v-treeview>
  </div>
</template>

<script>

import { AbreRelatorioExterno, MenuRelatorioGet } from "../../../services/global"

export default {
  name: "MenuRelatorios",

  props: ['aplicacao_nome'],

  data() {
    return {
  
      AbreRelatorioExterno  : AbreRelatorioExterno,
      MenuRelatorioGet      : MenuRelatorioGet,
      
      // Component properties e métodos aqui
      tree_opcoes           : null,
      menu_config           : [],
    }
  },

   async mounted() {
    // Busca menu para esta aplicacao
    await this.menu_relatorio(this.aplicacao_nome);

    this.configureTreeview(this.$el);
   },

   methods: {
    // Abrindo Relatorio Externo
    async abreRelatorioExterno(node) {
      console.log("🚀 ~ abreRelatorioExterno ~ node:", node)
      if (node) {
        if (node[0].id) {
          // Foi preciso usar variavel auxiliar para contornar erro do Vuetify quando clica pela segunda vez não pega o Node
          var l_Aux = this.menu_config;
          this.menu_config = [];
          await this.AbreRelatorioExterno(node[0].id);
          this.menu_config = l_Aux;
        }
      }
    },

    async menu_relatorio(p_programa) {
      var la_Res = [];
      la_Res = await this.MenuRelatorioGet(p_programa);
      this.menu_config = la_Res;
    },

    configureTreeview(element) {
      if (!element)
        return;

      // Intercepte o evento de clique apenas se houver filhos
      element.addEventListener('click', (event) => {
        event.stopPropagation();
      });

      // Recursivamente configure os elementos filhos
      const childElements = element.querySelectorAll('.v-treeview-node');
      childElements.forEach((child) => {
        this.configureTreeview(child);
      });
    }

   },

};
</script>
